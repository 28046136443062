<template>
  <div>
    <v-hover v-slot="{ isHovering, props }">
      <v-card
          class="mx-auto"
          v-bind="props"
          :height="e.is_circuit ? '150px' : '420px'">

        <v-card-subtitle class="pl-1 pr-1">
          <v-row no-gutters v-if="e.date_start">
            <v-col class="text-left pl-0 text-caption">
              <span>
                <v-icon class="ml-1" size="x-small" icon="fa-solid fa-calendar"></v-icon>
                {{ moment(e.date_start.$date).format('DD/MM/YY') }}
              </span>
              <span class=" ml-1" v-if="e.is_circuit && e.date_end">
                - {{ moment(e.date_end.$date).format('DD/MM/YY') }}
              </span>
            </v-col>
            <v-col class="text-right text-caption">
              <template v-if="!e.is_circuit">
                <span class="text-uppercase">{{$t(`constants.event_status.${e.status}`)}}</span>
                <v-icon class="ml-1" :icon="$root.get_icon_from_status(e.status)" :color="$root.get_icon_color_from_status(e.status)" size="x-small"></v-icon>
              </template>
            </v-col>
          </v-row>
        </v-card-subtitle>

        <v-card-subtitle class="bg-primary text-center" v-if="e.circuit">{{ e.circuit.name }}</v-card-subtitle>

        <v-img
            aspect-ratio="9/16"
            :cover="e.is_circuit ? true : false"
            :src="e.poster"
            :height="e.is_circuit ? '130px' : e.circuit ? '380px' : '400px'"
            lazy-src="https://mychip-dev-images.s3.eu-central-1.amazonaws.com/posters/default.png"
        >
          <v-btn icon size="x-small" class="mt-4 ml-4 hidden-sm-and-up" variant="elevated" :model-value="isHovering"  color="primary">
            <v-icon icon="fa-solid fa-circle-info fa-beat"></v-icon>
          </v-btn>

          <v-container fill-height>
            <v-row align="center"
                   justify="center">
              <v-col class="text-center text-white" :style="`position: absolute; top:${e.circuit ? '80' : '100'}px`">

                <template v-if="first_start">
                  {{void(diff = $root.now.diff(first_start)/1000)}}
                  <template v-if="diff < 0 && Math.abs(diff) < 2 * 86400">
                    <v-progress-circular class="bg-white rounded-pill" :model-value="100*Math.abs(diff)/86400" size="135" width="20" color="primary">
                    <span class="text-caption text-break">
                      <v-icon class="mr-1" size="x-small" icon="fa-solid fa-clock"></v-icon>
                      <span>faltan</span>
                      <br>
                      <span class="font-weight-bold text-body-1">{{ this.$root.parse_time_from_seconds(Math.abs(diff)) }}</span>
                    </span>
                    </v-progress-circular>
                  </template>
                  <template v-if="e.status == 'waiting_for_start'">
                    <v-progress-circular class="bg-white rounded-pill" indeterminate size="135" width="20" color="primary">
                      <span class="text-caption">esperando <br> para <br> la salida</span>
                    </v-progress-circular>
                  </template>
                </template>

              </v-col>
            </v-row>
          </v-container>

            <v-overlay
                :model-value="isHovering"
                contained
                persistent
                scrim="#1a222d"
                class="align-center justify-center"
            >
              <v-container fill-height fluid>
                  <v-row align="center"
                         justify="center">
                    <v-col class="pa-2 text-center text-white" v-if="!e.is_circuit">

                    <span v-if="!e.is_circuit && e.location && e.location.city" class="mb-2">
                      <v-icon class="ml-1" size="x-small" icon="fa-solid fa-location-pin"></v-icon>
                      {{ e.location.city }}
                    </span>

                      <h3 class="text-uppercase">{{ e.name_short || e.name }}</h3>


                      <v-chip-group column variant="flat" class="justify-center align-center">
                        <template v-for="m in e.modalities">
                          <v-chip label size="small" class="bg-white" style="pointer-events: none">
                            <v-icon :icon="$root.get_icon_from_sport(m.sport)" start size="x-small"></v-icon>
                            {{ $root.parse_distance(m.distance, true, true) }}
                          </v-chip>
                        </template>
                      </v-chip-group>

                      <template v-if="e.status == 'live'">
                        <v-btn :to="{ name: 'Event', params: { event_id: e.slug }}" style="" variant="elevated" color="primary" block>
                          <v-icon size="small" color="error" class="fa-solid fa-circle fa-beat"></v-icon>
                          <span class="ml-1">Seguimiento</span>
                        </v-btn>
                      </template>
                      <template v-else-if="['finished_provisional', 'finished_official'].includes(e.status)">
                        <v-btn :to="{ name: 'Event', params: { event_id: e.slug }}" style="" variant="elevated" color="primary" block>
                          <v-icon size="small" class="fa-solid fa-flag-checkered"></v-icon>
                          <span class="ml-1">Resultados</span>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn :to="{ name: 'Event', params: { event_id: e.slug }}" style="" variant="elevated" color="primary" block>+ info</v-btn>
                      </template>

                    </v-col>
                    <v-col class="pa-2 text-center text-white" v-else>
                      <h3 class="text-uppercase">{{ e.name_short || e.name }}</h3>
                      <v-btn :to="{ name: 'Circuit', params: { circuit_id: e.slug }}" style="" variant="elevated" color="primary" block>+ info</v-btn>
                    </v-col>
                  </v-row>

              </v-container>
            </v-overlay>
        </v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<style scoped>
>>>.v-img__img--cover {
  /*filter: blur(3px)!important;*/
}

>>>.v-overlay__scrim {
  opacity: .9!important;
}
</style>

<style>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  /*border: 5px solid #F9A825;*/
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
  /*color: #fff;*/
  font: 500 16px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

.featured span {
  background-color: #4ec2c5;
  color: #1a222d;
}


.external span {
  background-color: rgba(28, 19, 19, 0.45);
  color: #939393;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 24px;
  transform: rotate(30deg);
}
</style>

<script>
export default {
  name: "EventCardV2",
  props: {
    e: null,
  },
  data: () => ({
    first_start: null,
  }),
  created() {
    let first_start = this.moment(0)
    if ( this.e.modalities ) {
      first_start = this.moment.utc(this.e.modalities[0].datetime_start.$date)
      this.e.modalities.forEach(m => {
        if ( m.datetime_start ) {
          let start = this.moment.utc(m.datetime_start.$date).utc(true)
          if ( start < first_start ) {
            first_start = start
          }
        }
      })
      this.first_start = first_start
    }
  },
  computed: {
    moment: {
      get: function () {
        return this.$root.$data.moment
      }
    }
  }
};
</script>